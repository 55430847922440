import { useMemo } from 'react';
import { ILessonSubLesson } from 'types';

import { formatVerses } from '../../utils';
import NamesTable from '../namesTable';

interface ISublessonsProp {
  lesson: ILessonSubLesson;
  versesLinks: Record<string, string>;
  sectionsLinks: {
    [key: string]: number;
  };
  table: {
    header: string[];
    rows: string[];
  };
  setVideoActiveMinute: (e: number) => void;
  onVerseLinkClick?: (event: any) => void;
}

function LessonItem({ lesson, table, versesLinks, onVerseLinkClick, setVideoActiveMinute, sectionsLinks }: ISublessonsProp) {
  const sublesson = useMemo(() => {
    const formattedBodyText = formatVerses(lesson.bodyText, versesLinks);

    return {
      sectionTitle: lesson.sectionTitle,
      bodyText: formattedBodyText,
    };
  }, [lesson, versesLinks]);

  return (
    <div className="sublessons__item">
      <p
        id={sublesson.sectionTitle.split(' ').join('-')}
        className="sublessons__title"
        onClick={() => setVideoActiveMinute(sectionsLinks[sublesson.sectionTitle])}
      >
        {sublesson.sectionTitle}
      </p>
      <div className="sublessons__description-wrapper">
        <div
          className="sublessons__description"
          dangerouslySetInnerHTML={{ __html: sublesson.bodyText }}
          onClick={onVerseLinkClick}
        />
        {table && (
          <NamesTable
            data={table}
            versesLinks={versesLinks}
            onClick={onVerseLinkClick}
          />
        )}
      </div>
    </div>
  );
}

export default LessonItem;
