import React from 'react';
import { Table } from 'antd';

import { formatVerses } from '../utils';

function NamesTable({ data, versesLinks, onClick }) {
  const columns = data.header.map((title, index) => ({
    title,
    dataIndex: `col${index}`,
    key: `col${index}`,
    width: `${100 / data.header.length}%`,
    render: text => (
      <div
        dangerouslySetInnerHTML={{ __html: formatVerses(text, versesLinks) }}
        onClick={onClick}
      />
    ),
  }));

  const dataSource = data.rows.map((row, index) => {
    const rowData = { key: index };
    row.forEach((cell, cellIndex) => {
      rowData[`col${cellIndex}`] = cell;
    });
    return rowData;
  });

  return <Table columns={columns} dataSource={dataSource} pagination={false} bordered />;
}

export default NamesTable;
