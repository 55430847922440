import { ILessonSubLesson } from 'types';

import LessonItem from './lessonItem';

interface ISublessonsProp {
  sublessons: ILessonSubLesson[];
  versesLinks: Record<string, string>;
  sectionsLinks?: {
    [key: string]: number;
  };
  setVideoActiveMinute: (e: number) => void;
  onVerseLinkClick?: (event: any) => void;
}

function Sublessons({ sublessons, versesLinks, sectionsLinks, setVideoActiveMinute, onVerseLinkClick }: ISublessonsProp) {
  return (
    <div className="sublessons">
      {sublessons?.map(
        sublesson => (
          <LessonItem
            key={sublesson.sectionTitle}
            lesson={sublesson}
            table={sublesson.table}
            versesLinks={versesLinks}
            setVideoActiveMinute={setVideoActiveMinute}
            sectionsLinks={sectionsLinks || {}}
            onVerseLinkClick={onVerseLinkClick}
          />
        ),
      )}
    </div>
  );
}

export default Sublessons;
